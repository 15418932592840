(function($) {
    var reInitGCaptcha;
    
    fn_nl_lib_anchor();
    
    reInitGCaptcha = function() {
        $.getScript("https://www.google.com/recaptcha/api.js?render=6LeDdMIUAAAAAJc8InMGxAmIX98_BAKVodcaTsz4").done(function () {
            grecaptcha.ready(function () {
                grecaptcha.execute('6LeDdMIUAAAAAJc8InMGxAmIX98_BAKVodcaTsz4', {action: 'form'})
                    .then(function (token) {
                        $('#g-token').val(token);
                    });
            });
        });
    };
    
    if ($('form[data-recaptcha]').length) {
        reInitGCaptcha();
    }

    $(document).on('lazybeforeunveil', '.lazyload', function(e){
        $(e.target).one('load', function() {
            if ($(this).parent().hasClass("lib--lazyload")) {
                $(this).parent().removeClass("lib--lazyload").addClass("lib--lazyloaded");
            }
        });
    });
    

    nl_lib_dialog.init(function(){
        $(document).find(".lib--dialog .part_ui_btn").nl_lib_ripple();
    });
    $("[data-switch]").nl_lib_switch();
    $(".part_ui_btn").nl_lib_ripple();

    var picture = document.createElement('picture'),
        strObj = '' + picture;
    if(strObj.indexOf("HTMLPictureElement") === -1 && !$("html").hasClass("ie"))
    {
        $.getScript($cdnjs.picturefill);
    }
    if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
        $html.addClass("ie11");
    }
    
    // AJAX FORM+
    $(document).on('submit', 'form.ajax', function (e) {
        e.preventDefault();
        var $frm = $(this);
        var formData = new FormData($(this)[0]);
        $.ajax({
            method: $frm.attr('method'),
            url: $frm.attr('action'),
            data: formData,
            processData: false,
            contentType: false
        }).done(function(){
            if ($('form[data-recaptcha]').length) {
                reInitGCaptcha();
            }            
        });
    });

    // ALAX LINKS
    $(document).on('click', '[href].ajax', function (e) {
        e.preventDefault();
        var $el = $(this);
        $.ajax({
            url: $el.attr('href'),
            data: {ajax: 1}
        });
    });
    
    // gallerie
    $(document).on('click', '[data-changegallery]', function (e) {
        e.preventDefault();
        var $el = $(this);
        $.ajax({
            url: $el.attr('href'),
            data: {ajax: 1}
        }).done(function(){
            $('[data-changegallery]').removeClass('mod--active');
            $el.addClass('mod--active');
            gallery($(".comp_gallery"));
            sr.sync();
        });
    });
    
    // tipy
    $(document).on('click', '[data-changetype]', function (e) {
        e.preventDefault();
        var $el = $(this);
        $.ajax({
            url: $el.attr('href'),
            data: {ajax: 1}
        }).done(function(){
            $('[data-changetype]').removeClass('mod--active');
            $el.addClass('mod--active');
        });
    });

    var antispam = function () {
        if ($('#snippet-contactform').length) {
            setTimeout(function () {
                $('#snippet-contactform').append('<input type="hidden" class="text" name="antispam" value="antispam" />');
            }, 3000);
        }
    };

    var antispamnewsletter = function () {
        if ($('#snippet-newsletter').length) {
            setTimeout(function () {
                $('#snippet-newsletter').append('<input type="hidden" class="text" name="antispam" value="antispam" />');
            }, 2000);
        }
    };
    
    $(document).ready(function () {
        //antispam();
        antispamnewsletter();
    });
    
    /* GLOBAL AJAX EVENT HANDLER */
    $.ajaxSetup({
        dataType: "json",
        success: function (payload) {
            if (payload) {
                // přesměrování "this" přesměruje na aktuální stránku
                if (payload.redirect) {
                    if (payload.redirect == 'this') {
                        location.href = window.location.href.split("#")[0];
                    } else {
                        location.href = payload.redirect;
                    }
                }

                // výměna snippetů v šabloně - každý snippet musí mít unikátní ID
                if (payload.snippets) {
                    for (var i in payload.snippets) {
                        $("#" + i).replaceWith(payload.snippets[i]);
                    }
                }

                // výměna adresy v adresním řádku
                if (payload.replaceState) {
                    window.history.replaceState(null, payload.replaceState.title || null, payload.replaceState.url);

                    if (payload.replaceState.title) {
                        document.title = payload.replaceState.title;
                    }

                    if (typeof window.ga === "function") {
                        ga('send', 'pageview', window.location.pathname + window.location.search);
                    }
                }
                antispam();
                antispamnewsletter();
            }
        }
    });
    
    var lang = $('html').attr('lang');
    var getUrlParameter = function getUrlParameter(sParam) {
        var sPageURL = decodeURIComponent(window.location.search.substring(1)), sURLVariables = sPageURL.split('&'), sParameterName, i;
        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : sParameterName[1];
            }
        }
    };
    var url = "https://www.secure-hotel-booking.com/Hotel-Na-Plazi/2C3A/"+lang+"/?hotelId=19039&showSearch=true";
    var arrival = getUrlParameter('arrivalDate');
    var departure = getUrlParameter('departureDate');
    var adult = getUrlParameter('selectedAdultCount');
    var rooms = getUrlParameter('rooms');
    var pattern = /(\d{2})\.(\d{2})\.(\d{4})/;

    if (arrival) {
    	var dtArrival = new Date(arrival.replace(pattern,'$3-$2-$1'));
        var dArrival = new Date(dtArrival);
        var dayArrival = dArrival.getDate();
        if(dayArrival < 10) {
            dayArrival = '0'+dayArrival;
        }
        var monthArrival = dArrival.getMonth() + 1;
        if(monthArrival < 10) {
            monthArrival = '0'+monthArrival;
        }
        var yearArrival = dArrival.getFullYear();

        url += '&arrivalDate=' + yearArrival +'-' + monthArrival + '-' + dayArrival;
    }

    if (departure) {
        var dtDeparture = new Date(departure.replace(pattern,'$3-$2-$1'));
        var dDeparture = new Date(dtDeparture);
        var dayDeparture = dDeparture.getDate();
        if(dayDeparture < 10) {
            dayDeparture = '0'+dayDeparture;
        }
        var monthDeparture = dDeparture.getMonth() + 1;
        if(monthDeparture < 10) {
            monthDeparture = '0'+monthDeparture;
        }
        var yearDeparture = dDeparture.getFullYear();
        url += '&departureDate=' + yearDeparture +'-' + monthDeparture + '-' + dayDeparture;
    }

    if(adult) {
        url += '&selectedAdultCount=' + adult;
    }

    if(rooms) {
        url += '&roomId=' + rooms;
    }

    if ($("#myFrame").length) {
        document.getElementById("myFrame").src = url;
    }
})(jQuery);
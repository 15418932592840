function gallery($comp) {
    $comp.find("[data-lightgallery-list]").lightGallery({
        thumbnail: true,
        selector: ".part_item_gallery",
        exThumbImage: 'data-exthumbimage',
        fullScreen: false,
        zoom: true,
        actualSize: false,
        hash: false,
        download: true,
        autoplay: false,
        autoplayControls: false
    });
};
(function ($) {
    let $fn = $(".part_item_room");

    if ($fn.length) {
        $fn.find("[data-lightgallery-list]").lightGallery({
            thumbnail: true,
            selector: ".elm_gallery_item",
            exThumbImage: 'data-exthumbimage',
            fullScreen: false,
            zoom: true,
            actualSize: false,
            hash: false,
            download: true,
            autoplay: false,
            autoplayControls: false
        });
    }
})(jQuery);
function fn_ajax_handler(payload,callback) {
    if (payload) {
        // přesměrování "this" přesměruje na aktuální stránku
        if (payload.redirect) {
            if (payload.redirect === 'this') {
                location.href = window.location.href.split("#")[0];
            } else {
                location.href = payload.redirect;
            }
        }

        // výměna snippetů v šabloně - každý snippet musí mít unikátní ID
        if (payload.snippets) {
            for (let i in payload.snippets) {
                $("#" + i).replaceWith(payload.snippets[i]);
            }
        }

        // výměna adresy v adresním řádku
        if (payload.replaceState) {
            window.history.replaceState(null, payload.replaceState.title || null, payload.replaceState.url);

            if (payload.replaceState.title) {
                document.title = payload.replaceState.title;
            }

            if (typeof window.ga === "function") {
                ga('send', 'pageview', window.location.pathname + window.location.search);
            }
        }

        if (callback) {
            callback();
        }
    }
}
(function ($) {
    let $fn = $(".part_item_block");

    if ($fn.length) {

        //slider

        let $carousel = $fn.find("[data-carousel]");

        if($carousel.children().length > 1){
            cssLoaded(function () {
                $carousel.flickity({
                    pageDots: true,
                    wrapAround: true,
                    contain: true,
                    cellAlign: 'left',
                    prevNextButtons: false,
                    autoPlay: true
                })
            });
        }

        //gallery

        $fn.find("[data-lightgallery-list]").lightGallery({
            thumbnail: true,
            selector: ".elm_gallery_item",
            exThumbImage: 'data-exthumbimage',
            fullScreen: false,
            zoom: true,
            actualSize: false,
            hash: false,
            download: true,
            autoplay: false,
            autoplayControls: false
        });
    }
})(jQuery);
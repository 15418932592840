(function ($) {
    let $fn = $(".comp_booking_bar");

    if ($fn.length) {
        //input labels

        $fn.find("[data-label]").each(function () {
            let $this = $(this);
            let $label_width = parseInt(window.getComputedStyle($this.get(0), ':before').width)+20;

            // $this.find("input").css('padding-left',$label_width);
        });

        //datepickers

        let $lang = $("html").attr("lang");
        $.getScript("https://cdnjs.cloudflare.com/ajax/libs/air-datepicker/2.2.3/js/i18n/datepicker."+$lang+".min.js").done(function () {
            let $dp_in_alt = $fn.find("[data-datepicker-alt=\"arrival\"]"),
                $dp_out_alt = $fn.find("[data-datepicker-alt=\"departure\"]"),
                $today = new Date();

            let $dp_options = {
                language: $lang,
                dateFormat: "dd.mm.yyyy",
                altFieldDateFormat: "yyyy-mm-dd",
                position: 'top left',
            };

            let $dp_in = $fn.find("[data-datepicker=\"arrival\"]").datepicker($.extend({},$dp_options,{
                altField: $dp_in_alt,
                minDate: $today,
                onSelect:function ($fd,$d,$inst) {
                    console.log($d);
                    $dp_out.update({
                        minDate: new Date($d.getTime() + (24*60*60*1000))
                    });
                    setTimeout(function () {
                        console.log(new Date($dp_out['$el'].siblings("input").val()));
                        if($d > new Date($dp_out['$el'].siblings("input").val())){
                            console.log("change date");
                            $dp_out.selectDate(new Date($d.getTime() + (24*60*60*1000)))
                        }
                    },50);
                }
            })).data('datepicker');

            let $dp_out = $fn.find("[data-datepicker=\"departure\"]").datepicker($.extend({},$dp_options,{
                altField: $dp_out_alt,
                minDate: new Date($today.getTime() + (24*60*60*1000)),
                startDate: new Date($today.getTime() + (24*60*60*1000)),
                onSelect:function ($fd,$d,$inst) {

                }
            })).data('datepicker');

            $dp_in.selectDate($today);
            $dp_out.selectDate(new Date($today.getTime() + (24*60*60*1000)));
        });
    }
})(jQuery);

(function($) {
    var $fn = $("#layout_header"),
        $header = $fn.find(".wrp_header");

    if ($fn.length) {
        $fn.after('<nav id="layout_nav" class="ssm-nav" role="presentation"><div class="elm_head"><a data-ssm-toggle=""><i class="icon icon--close"></i></a></div><div class="elm_content"></div></nav>');

        var $layout_nav = $(document).find("#layout_nav"),
            $logo = $header.find(".elm_logo").clone(),
            $nav = $header.find(".elm_nav").clone();

        $layout_nav.find(".elm_head").append($logo);
        $layout_nav.find(".elm_content").append($nav);

        $('.ssm-nav').slideAndSwipe();

        if(!$fn.hasClass("mod--header-booking")){
            var headroom = new Headroom($fn[0], {
                offset: 5,
                tolerance : {
                    up : 5,
                    down : 0
                },
                classes: {
                    "initial" : "headroom",
                    "pinned": "headroom--pinned",
                    "unpinned": "headroom--unpinned",
                    "top" : "headroom--top",
                    "notTop" : "headroom--not-top"
                }
            });
            headroom.init();
        }
    }
})(jQuery);
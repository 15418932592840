(function ($) {
    let $fn = $(".comp_visual");

    if ($fn.length) {
        cssLoaded(function() {
            let $carousel = $fn.find("[data-visual]"),
                $carousel_caption = $fn.find("[data-visual-caption]"),
                $carousel_autoplay = $carousel.data("visual");

            let $autoplay = $carousel_autoplay;
            if ($carousel_autoplay === "0" || $carousel_autoplay === "") {
                $autoplay = false;
            }

            let $carousel_caption_for = $carousel_caption[0];
            let $carousel_for = $carousel[0];

            if ($carousel_caption.children().length < 1 || $carousel.children().length < 1) {
                $carousel_caption_for = null;
                $carousel_for = null;
            }

            if($carousel.children().length > 1){
                $carousel_caption.flickity({
                    contain: true,
                    pageDots: false,
                    prevNextButtons: false,
                    wrapAround: true,
                });

                $carousel.flickity({
                    imagesLoaded: false,
                    percentPosition: false,
                    setGallerySize: false,
                    pageDots: true,
                    wrapAround: true,
                    contain: true,
                    sync: $carousel_caption_for,
                    autoPlay: $autoplay,
                    prevNextButtons: false,
                    pauseAutoPlayOnHover: true
                });
            }
            else {
                $carousel_caption.find(".elm_item").first().addClass("is-selected");
            }
        });
    }
})(jQuery);